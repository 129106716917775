body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

#root {
  background-color: #dcdcdc;
}

.title {
  font-size: 1.25rem;
  margin-top: 1rem;
  text-align: center;
}

button {
  padding: 0 0.25rem;
}

@media (min-width: 480px) and (min-height: 360px) {
  .title {
    font-size: 2rem;
    margin-top: 1rem;
  }

  button {
    font-size: 1.5rem;
    padding: 0 0.5rem;
  }
}

@media (min-width: 360px) and (min-height: 400px) {
  .title {
    font-size: 2.5rem;
    margin-top: 1rem;
  }

  button {
    font-size: 1.75rem;
    padding: 0 0.25rem;
  }
}

@media (min-width: 600px) and (min-height: 768px) {
  .title {
    font-size: 5rem;
    margin-top: 2rem;
  }

  button {
    font-size: 4rem;
    padding: 0 1rem;
  }
}
