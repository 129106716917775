.time {
  font-size: 4rem;
}

.red {
  color: red;
}

.control {
  margin-left: -0.5rem;
  margin-bottom: 2rem;
}

.control button {
  margin-left: 0.5rem;
}

@media (min-width: 200px) {
  .control {
    margin-left: -1rem;
  }

  .control button {
    margin-left: 1rem;
  }
}

@media (min-width: 260px) and (min-height: 400px) {
  .time {
    font-size: 7.5rem;
  }
}

@media (min-width: 480px) and (min-height: 260px) {
  .control {
    margin-left: -2rem;
  }

  .control button {
    margin-left: 2rem;
  }

  .time {
    font-size: 10rem;
  }
}

@media (min-width: 600px) and (min-height: 768px) {
  .time {
    font-size: 18rem;
  }
}

@media (min-width: 1000px) and (min-height: 850px) {
  .time {
    font-size: 30rem;
  }
}
