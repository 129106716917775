.buttonContainer {
  text-align: center;
  margin-bottom: 0.5rem;
}

@media (min-width: 260px) and (min-height: 400px) {
  .buttonContainer {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 480px) and (min-height: 320px) {
  .buttonContainer {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 600px) and (min-height: 768px) {
  .buttonContainer {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 600px) and (min-height: 800px) {
  .buttonContainer {
    margin-bottom: 2.5rem;
  }
}
