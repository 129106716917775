.copyright {
  font-size: 0.75rem;
  margin-bottom: 1rem;
}

@media (min-width: 480px) and (min-height: 360px) {
  .copyright {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 360px) and (min-height: 400px) {
  .copyright {
    font-size: 1.25rem;
  }
}

@media (min-width: 600px) and (min-height: 768px) {
  .copyright {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
